import React from 'react'
import {Link} from 'react-router-dom'
import SidebarItemProps from '../sidebarItem'
import './styles.css'

const List: React.FC<{}> = () => {
  return (
    <SidebarItemProps title="Recipes by origin" noWrapper>
      <div className="list">
        <Link to="/origin/americas">Americas</Link>
        <Link to="/origin/asia">Asia</Link>
        <Link to="/origin/europe">Europe</Link>
        <Link to="/origin/japan">Japan</Link>
        <Link to="/origin/norway">Norway</Link>
        <Link to="/origin/australia">Australia</Link>
      </div>
    </SidebarItemProps>
  )
}

export default List
