import React from 'react'
import { Link } from 'react-router-dom'
import { ETags } from '../../utils'
import { filterRecipesByTag } from '../../functions'
import SidebarItemProps from '../sidebarItem'
import './styles.css'

const Beverages: React.FC<{}> = () => {
  const drinkRecipes = filterRecipesByTag(ETags.drink)
  const drinRecipesList = drinkRecipes.map((x) => {
    return (
      <Link to={`/recipe/${x.name}`} key={x.name}>
        <div className="drink_recipe">
          <img src={`/recipe/small-${x.photos[0]}`} alt={x.name} width="50" height="50" />
          <div>
            <h3>{x.title}</h3>
            <div>open recipe</div>
          </div>
        </div>
      </Link>
    )
  })
  return (
    <SidebarItemProps title="Beverages">
      <div className="list">{drinRecipesList}</div>
    </SidebarItemProps>
  )
}

export default Beverages
