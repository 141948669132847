export enum ETags {
  all = 'all',
  alcohol = 'alcohol',
  apple = 'apple',
  avocado = 'avocado',
  banana = 'banana',
  bechamel = 'bechamel',
  biscuit = 'biscuit',
  blueberry = 'blueberry',
  bread = 'bread',
  brownies = 'brownies',
  butter = 'butter',
  cake = 'cake',
  caramel = 'caramel',
  cheese = 'cheese',
  chicken = 'chicken',
  chickpeas = 'chickpeas',
  chocolate = 'chocolate',
  cinnamon = 'cinnamon',
  coconut = 'coconut',
  cookies = 'cookies',
  courgette = 'courgette',
  creamCheese = 'creamCheese',
  crumble = 'crumble',
  drink = 'drink',
  egg = 'egg',
  fish = 'fish',
  garlic = 'garlic',
  healthy = 'healthy',
  herbs = 'herbs',
  lentils = 'lentils',
  mousse = 'mousse',
  myrtle = 'myrtle',
  noodle = 'noodle',
  nutmeg = 'nutmeg',
  oatmeal = 'oatmeal',
  pasta = 'pasta',
  peanuts = 'peanuts',
  pesto = 'pesto',
  plum = 'plum',
  pork = 'pork',
  potato = 'potato',
  prunes = 'prunes',
  rhubarb = 'rhubarb',
  rice = 'rice',
  simple = 'simple',
  soup = 'soup',
  spices = 'spices',
  tart = 'tart',
  traditional = 'traditional',
  vegetable = 'vegetable',
  walnut = 'walnut',
}

export enum EOrigin {
  americas = 'Americas',
  asia = 'Asia',
  australia = 'Australia',
  europe = 'Europe',
  japan = 'Japan',
  norway = 'Norway',
  notSet = 'Not set',
  sarahAuffret = 'Sarah Auffret',
}

export enum EComplexity {
  veryEasy = 'very easy',
  easy = 'easy',
  medium = 'medium',
  advanced = 'advanced',
  notSet = 'Not set',
}

export enum ECourse {
  snacks = 'Snacks',
  starter = 'Starter',
  main = 'Main',
  dessert = 'Dessert',
  notSet = 'Not set',
}

export interface ILink {
  name: string
  link: string
}

export interface IRecipe {
  needHelp: boolean
  name: string
  dateAdded: string
  title: string
  totalTime: string
  recipeYield: number
  complexity: string
  ingredients: string[]
  directions: string[]
  description: string[]
  tags: ETags[]
  photos: string[]
  specialTip?: string
  origin?: EOrigin[]
  rescuer?: string
  course?: ECourse[]
  links?: ILink[]
}
export const defaultRecipe: IRecipe = {
  needHelp: false,
  name: '',
  dateAdded: '',
  title: '',
  totalTime: '',
  recipeYield: 0,
  complexity: EComplexity.notSet,
  origin: [EOrigin.notSet],
  ingredients: [],
  directions: [],
  description: [],
  specialTip: '',
  tags: [],
  photos: [],
  rescuer: '',
  course: [ECourse.notSet],
  links: []
}
