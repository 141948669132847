import React, { useState } from 'react'
import platform from 'platform'
import Logo from '../logo'
import Menu from '../menu'
import './styles.css'

interface MenuButtonProps {
  showMenu: boolean
  onClick: Function
}
const MenuButton: React.FC<MenuButtonProps> = ({ showMenu, onClick }) => {
  return (
    <button className="menu_button" onClick={() => onClick(!showMenu)} aria-label="toggle opening menu">
      <svg viewBox="0 0 100 100">
        {showMenu ? (
          <path
            style={{ fill: 'white' }}
            d="M59.65,50,74.13,35.53a4.57,4.57,0,0,0,0-6.44l-3.22-3.22a4.57,4.57,0,0,0-6.44,0L50,40.35,35.53,25.87a4.57,4.57,0,0,0-6.44,0l-3.22,3.22a4.57,4.57,0,0,0,0,6.44L40.35,50,25.87,64.47a4.57,4.57,0,0,0,0,6.44l3.22,3.22a4.57,4.57,0,0,0,6.44,0L50,59.65,64.47,74.13a4.57,4.57,0,0,0,6.44,0l3.22-3.22a4.57,4.57,0,0,0,0-6.44Z"
          />
        ) : (
          <path
            style={{ fill: 'white' }}
            d="M26.4,35.9h47.2c1,0,1.8-0.8,1.8-1.8c0,0,0,0,0,0v-4.5c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0H26.4
            c-1,0-1.8,0.8-1.8,1.8c0,0,0,0,0,0v4.5C24.6,35.1,25.4,35.9,26.4,35.9z M26.4,54.1h47.2c1,0,1.8-0.8,1.8-1.8c0,0,0,0,0,0v-4.5
            c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0H26.4c-1,0-1.8,0.8-1.8,1.8c0,0,0,0,0,0v4.5C24.6,53.3,25.4,54.1,26.4,54.1
            C26.4,54.1,26.4,54.1,26.4,54.1z M26.4,72.3h47.2c1,0,1.8-0.8,1.8-1.8c0,0,0,0,0,0v-4.5c0-1-0.8-1.8-1.8-1.8c0,0,0,0,0,0H26.4
            c-1,0-1.8,0.8-1.8,1.8c0,0,0,0,0,0v4.5C24.5,71.4,25.3,72.2,26.4,72.3C26.3,72.3,26.4,72.3,26.4,72.3z"
          />
        )}
      </svg>
    </button>
  )
}

const Header: React.FC<{}> = () => {
  const [showMenu, toggleShowMenu] = useState(false)
  const isMobile = platform.os && (platform.os.family === 'iOS' || platform.os.family === 'Android')
  return (
    <header className={isMobile ? 'mobile' : 'desktop'}>
      <div className="container">
        <Logo />
        <div className="links">
          {(!isMobile || showMenu) && <Menu onClick={toggleShowMenu} />}
          {isMobile && <MenuButton showMenu={showMenu} onClick={toggleShowMenu} />}
        </div>
      </div>
    </header>
  )
}

export default Header
