import React from 'react'
import { Link } from 'react-router-dom'
import categories from './categories.json'
import SidebarItemProps from '../sidebarItem'
import './styles.css'

function tags() {
  return categories.map((x) => {
    const { weight, name } = x
    return (
      <Link to={`/recipes/${name}`} className={`weight${weight}`} key={name}>
        {name}{' '}
      </Link>
    )
  })
}

const TagCloud: React.FC<{}> = () => {
  return (
    <SidebarItemProps title="Fancy Some...">
      <div className="cloud">{tags()}</div>
    </SidebarItemProps>
  )
}

export default TagCloud
