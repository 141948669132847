import React from 'react'
import './styles.css'

interface SidebarItemProps {
  title: string
  noWrapper?: boolean
}
const SidebarItem: React.FC<SidebarItemProps> = ({title, noWrapper, children}) => {
  return (
    <section className={`sidebar_item ${noWrapper ? 'unwrapped' : 'wrapped'}`}>
      <div className="sidebar_item_internal_wrapper">
        <h2>
          {title}
        </h2>
        {children}
      </div>
    </section>
  )
}

export default SidebarItem
