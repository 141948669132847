import React from 'react'
import { NavLink } from 'react-router-dom'
import './styles.css'

interface MenuProps {
  onClick: Function
}

const Menu: React.FC<MenuProps> = ({ onClick }) => {
  return (
    <menu className="header_menu">
      <NavLink activeClassName="active" exact to="/" onClick={() => onClick()}>
        Home
      </NavLink>
      <NavLink activeClassName="active" to="/recipes/all" onClick={() => onClick()}>
        Sarah's Recipes
      </NavLink>
      {/* <NavLink activeClassName="active" to="/blog">
        Blog
      </NavLink> */}
    </menu>
  )
}

export default Menu
