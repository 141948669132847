import React from 'react'
import './styles.css'

const PageWrapper: React.FC<{}> = ({ children }) => {

  return (
    <main>
      <div className="container">
          {children}
      </div>
    </main>
  )
}

export default PageWrapper
