import React from 'react'
import {Link} from 'react-router-dom'
import SidebarItemProps from '../sidebarItem'
import './styles.css'

const RecipeByCourse: React.FC<{}> = () => {
  return (
    <SidebarItemProps title="Recipes by course" noWrapper>
      <div className="list">
        <Link to="/courses/snacks">Snacks</Link>
        <Link to="/courses/starter">Starter</Link>
        <Link to="/courses/main">Main</Link>
        <Link to="/courses/dessert">Dessert</Link>
      </div>
    </SidebarItemProps>
  )
}

export default RecipeByCourse
