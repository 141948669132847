import React from 'react'
import './styles.css'

const Logo: React.FC<{}> = () => {
  return (
    <a href="/" className="logo">Too Many Dishes</a>
  )
}

export default Logo
