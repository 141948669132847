import React, { useState, useEffect } from 'react'
import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import platform from 'platform'
import Header from './components/header'
import Footer from './components/footer'
import PageWrapper from './components/pageWrapper'
import TagCloud from './components/tagCloud'
import RecipeByCourse from './components/byCourse'
import RecipeByOrigin from './components/byOrigin'
import Beverages from './components/beverages'
import './App.css'

const Home = React.lazy(() => import('./pages/home'))
const Recipes = React.lazy(() => import('./pages/recipes'))
const Courses = React.lazy(() => import('./pages/courses'))
const Origin = React.lazy(() => import('./pages/origin'))
const Recipe = React.lazy(() => import('./pages/recipe'))
const Blog = React.lazy(() => import('./pages/blog'))
const Legal = React.lazy(() => import('./pages/legal'))
const Contact = React.lazy(() => import('./pages/contact'))
const AboutSarah = React.lazy(() => import('./pages/about-sarah'))
const NotFound = React.lazy(() => import('./pages/not-found'))
const NeedHelp = React.lazy(() => import('./pages/need-help'))

const App: React.FC<{}> = () => {
  const isMobile = platform.os && (platform.os.family === 'iOS' || platform.os.family === 'Android')
  const location = useLocation()
  const path = location.pathname
  const [showSideColumn, setShowSideColumn] = useState(path === '/')

  useEffect(() => setShowSideColumn(path === '/'))

  return (
    <div className={`App ${isMobile ? 'mobile' : 'desktop'}`}>
      <Header />
      <PageWrapper>
        <div className={`page_wrapper_grid ${showSideColumn ? 'with_side_column' : 'without_side_column'}`}>
          <Switch>
            <Redirect from="/my-recipes/tag/recipes/:tag" to="/recipes/:tag" />
            <Route exact path="/" component={Home} />
            <Route exact path="/recipes/:tag" component={Recipes} />
            <Route path="/recipe/:name" component={Recipe} />
            <Route path="/courses/:course" component={Courses} />
            <Route path="/origin/:origin" component={Origin} />
            <Route path="/blog/:article" component={Blog} />
            <Route path="/legal" component={Legal} />
            <Route path="/contact" component={Contact} />
            <Route path="/about-sarah" component={AboutSarah} />
            <Route path="/need-help" component={NeedHelp} />
            <Route path="/not-found" component={NotFound} />
          </Switch>
          {showSideColumn && (
            <div className="side_column">
              <TagCloud />
              <RecipeByCourse />
              <Beverages />
              <RecipeByOrigin />
            </div>
          )}
        </div>
      </PageWrapper>
      <Footer />
    </div>
  )
}

export default App
