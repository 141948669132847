import { IRecipe, ETags, EOrigin, EComplexity, ECourse } from './utils'

export const recipes: IRecipe[] = [
  {
    needHelp: false,
    name: 'eggnog',
    dateAdded: '24.05.2011',
    title: 'Egg Nog',
    totalTime: 'PT2H',
    recipeYield: 5,
    complexity: EComplexity.medium,
    origin: [EOrigin.americas],
    ingredients: [
      '4 egg yolks',
      '500ml milk',
      '100g sugar',
      '250ml cream',
      '2 tsp cloves',
      '1 tsp nutmeg',
      '1 tsp vanilla essence',
      'a pinch of cinnamon',
    ],
    directions: [
      'In a large bowl, beat the egg yolks until they become light in colour. Slowly beat in the sugar and whisk until light and fluffy.',
      'Combine the milk, cinnamon and cloves in a thick bottomed saucepan. Slowly heat on medium heat until it is steaming hot but not boiling.',
      'Temper the eggs by slowly adding the hot milk mix to the eggs, whisking constantly. Pour the mix back into the saucepan.',
      'Cook on medium heat, stirring constantly with a wooden spoon until the mix thickens and coats the back of the spoon slightly. DO NOT BOIL!',
      'Remove from heat and stir in the cream. Cool down for an hour.',
      'Add nutmeg and chill in the freezer for 10 minutes. Serve in small glasses.',
    ],
    description: [
      "For me, egg nog used to be a strange Christmas drink from the movies. The idea of cream, eggs and spices together didn't appeal to me at all!",
      'Then I was asked to organize a cooking afternoon before the end of year vacation for my high school students in Japan and the only festive non-alcoholic drink I could find was egg nog. I am so glad I got the opportunity to discover this drink!',
      'I have not added alcohol to the recipe, but feel free to do so!',
    ],
    specialTip: '',
    tags: [ETags.cinnamon, ETags.nutmeg, ETags.drink, ETags.egg],
    photos: ['sarah-school-japan-christmas.jpg'],
  },
  {
    needHelp: false,
    name: 'umeshyu',
    dateAdded: '05.06.2011',
    title: 'Umeshyu: Japanese Plum Wine',
    totalTime: 'P3M',
    recipeYield: 12,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.asia],
    ingredients: ['1KG sour plums (Japanese apricots)', '600g rock sugar', '1.8L white liquor/shochu'],
    directions: [
      'Soak the plums in water fo 3-4 hours.',
      'Take the stems off using a toothpick.',
      'Place thee plums in a big glass jar and add rock sugar',
      'Add shochu and place in a cool dark place.',
      'Shake about once a week for three months',
      'Send me a bottle!!!',
    ],
    description: [
      "It's umeshyu season! For the past three years in June, I had the chance to pick plums at my friend's orchard. She taught me and my friends how to make plum wine and three months later, we had a great party to taste each others' concoctions!",
      'So if you live in Japan, go pick some sour plums, get a big jar and make your own brew!',
    ],
    specialTip: 'You can try using different types of liquor.',
    tags: [ETags.alcohol, ETags.plum, ETags.traditional, ETags.drink],
    photos: ['sarah-picking-sour-plums.jpg'],
  },
  {
    needHelp: true,
    name: 'rhubarb-crumble',
    dateAdded: '09.06.2011',
    title: 'Rhubarb Crumble',
    course: [ECourse.dessert],
    totalTime: 'PT50M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'My friends and I decided to have a picnic by the sea to enjoy the first day of summer weather in Tromsø. We put several dishes together after gathering random ingredients from our kitchens.',
      "Everyone was really excited to eat their first rhubarb of the year- straight from my garden! Even though we were all full, we couldn't stop ourselves and finished it in no time!",
    ],
    tags: [ETags.rhubarb, ETags.cake, ETags.cinnamon],
    photos: ['sarah-rhubarb-picnic.jpg'],
    rescuer: '',
  },
  {
    needHelp: false,
    name: 'apple-walnut-cake',
    dateAdded: '24.05.2011',
    title: 'Apple Walnut Cake',
    course: [ECourse.dessert],
    totalTime: 'PT1H',
    recipeYield: 6,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.europe],
    ingredients: [
      '200 ml melted butter',
      '2 eggs',
      '2 cups of sugar',
      '1 apple peeled and diced',
      '80 gr chopped walnuts',
      '1 tsp (5 ml) baking powder',
      '1 tsp (5 ml) cinnamon',
      '1 tsp (5 ml) vanilla essence',
      '3 cups sifted flour ',
    ],
    directions: [
      'Preparation: Melt butter, beat eggs, dice apple, chop walnuts',
      'Mix beaten eggs, butter and sugar together and beat well',
      'Add everything else and mix till combined.',
      'Pour into a greased loaf tin and bake at 180˚C for 45 mins or until skewer comes out clean, rest for 5 minutes before turning out.',
    ],
    description: [
      'I got this recipe from a friend who had gotten it from a friend...and I have passed it on to many friends. Share this recipe because this cake is legendary!',
      'Nobody can resist this mix of spices, apples and nuts!',
    ],
    tags: [ETags.apple, ETags.walnut, ETags.cake, ETags.cinnamon, ETags.egg, ETags.butter],
    photos: ['sarah-apple-walnut-cake.jpg'],
    rescuer: "Brenda Loxley (Sarah's Grandmother)",
  },
  {
    needHelp: false,
    name: 'guacamole',
    dateAdded: '24.05.2011',
    title: 'Guacamole',
    course: [ECourse.snacks, ECourse.starter],
    totalTime: 'PT10M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.americas],
    ingredients: ['1 avocado', '1 small tomato', '50g of chopped onion', '1 garlic clove, chopped', 'salt and pepper', 'tabasco', 'lemon juice', 'Corn chips or sliced vegetables (carrots / cucumbers)'],
    directions: ['Mash the avocado', 'Chop the tomato, the garlic and onion', 'Mix all ingredients in a bowl', 'Add salt, pepper, tabasco and lemon juice to taste', 'Enjoy this delicious dip with chips or sliced vegetables', 'Bon appétit!'],
    description: [
      'If you can find some ripe & affordable avocados, this is the simplest snack to prepare and it will delight all your friends!',
      "You can add it to wraps, eat it as a dip with corn chips or vegetables- it's delicious with sticks of raw carrots or even chicory!",
    ],
    specialTip:
      'PS: did you know you can accelerate the maturation of avocados by keeping them next to ripe bananas or in a paper bag?',
    tags: [ETags.avocado],
    photos: ['sarah-guacamole.jpg'],
    rescuer: 'Yoshiko Shimada, Japan',
  },
  {
    needHelp: true,
    name: 'norwegian-chocolate-cake',
    dateAdded: '12.07.2011',
    title: 'Norwegian Chocolate Cake - with bananas!',
    course: [ECourse.dessert],
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.norway],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'My friends and I made a chocolate cake using the recipe at the back of Freia cocoa powder box last weekend. Of course I still had dozens of bananas to use so we added a few to the original recipe.',
      "Bananas make the cake so moist, it literally melts in your mouth! Freia is a good quality chocolate brand in Norway- if it's not available in your country, try Van Houten or an equivalent.",
    ],
    tags: [ETags.banana, ETags.chocolate, ETags.cake],
    photos: ['sarah-norwegian-chocolate-cake.jpg'],
    links: [{ name: 'Freia', link: 'https://www.freiahjemmekonditori.no/?gclid=CNacnfC6-6kCFQq_zAodxztVZA' }],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'banana-bread',
    dateAdded: '12.07.2011',
    title: 'Banana Bread',
    course: [ECourse.dessert, ECourse.snacks],
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'The local supermarket continues to have super banana deals so I find it hard not to buy more bananas every time I go by! Watch out for more banana recipes.',
      'I found this banana bread recipe on a French cooking blog and it turned out great. Give it a go :)!',
    ],
    tags: [ETags.banana, ETags.bread, ETags.cake, ETags.simple],
    photos: ['sarah-banana-bread.jpg'],
    links: [{ name: 'Sens du goût', link: 'http://sensdugout.canalblog.com/archives/2005/12/23/1137865.html' }],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'banana-cake',
    dateAdded: '07.07.2011',
    title: 'Banana Cake',
    course: [ECourse.dessert, ECourse.snacks],
    totalTime: 'PT1H10M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'My local supermarket was virtually giving bananas away yesterday: I got a bag full for 10Kr (1.30€)!',
      "I'd never cooked with bananas so it was a good opportunities to try different recipes. I chose to try this bbc cake recipe first and I'm trying to stop myself from eating it all right now (^^)!",
    ],
    tags: [ETags.banana, ETags.cake, ETags.cinnamon],
    photos: ['sarah-banana-cake.jpg'],
    links: [{ name: 'BBC cake recipe', link: 'https://www.bbcgoodfood.com/recipes/7380/banana-cake' }],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'lasagne',
    dateAdded: '05.06.2011',
    title: 'Lasagne',
    course: [ECourse.main],
    totalTime: 'PT2H',
    recipeYield: 0,
    complexity: EComplexity.medium,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I am not sure how Italian this recipe actually is, but it's simple&tasty! If I have a small dinner party, I often make lasagne as it easy to adapt it to everyone's taste - substitute the meat for more vegetables, tomato sauce for pesto, etc!",
      "As long as there is cheese and white sauce (béchamel), it'll taste amazing!",
    ],
    tags: [ETags.bechamel, ETags.pasta, ETags.cheese],
    photos: ['sarah-italy-lasagne.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'caramelslice',
    dateAdded: '24.09.2012',
    title: 'Caramel Slice',
    totalTime: 'PT1H30M',
    recipeYield: 0,
    complexity: EComplexity.medium,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "This is a very rich yet delicious snack or dessert! One of the local import shops had a clearance sale on caramel slices from Scotland last month but I haven't been able to find them since, so I decided to try and make my own. My house mate and I more or less followed the BBC Food recipe but we struggled a bit with the caramel and chocolate- it still tasted amazing though! If you have any advice on how to make a soft, yet not runny caramel please email me.",
    ],
    tags: [ETags.biscuit, ETags.caramel, ETags.butter, ETags.chocolate],
    photos: ['sarah-caramel-slice.jpg'],
    course: [ECourse.snacks],
    rescuer: '',
    links: [{ name: 'BBC', link: 'https://www.bbcgoodfood.com/recipes/1153653/caramel-slice' }],
  },
  {
    needHelp: false,
    name: 'blueberry-muffins',
    dateAdded: '03.06.2011',
    title: 'Blueberry muffins',
    totalTime: 'PT1H',
    recipeYield: 10,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia],
    ingredients: [
      '1 cup blueberries',
      '2 cups flour',
      '2/3 cup sugar',
      '2 tsp baking powder',
      '1/4 tsp baking soda',
      '1/2 tsp cinnamon',
      '1/4 tsp salt',
      '1 egg',
      '1 cup milk',
      '1 tsp vanilla essence',
      '3 tbs butter, melted',
      'topping: 1/4 cup flour',
      'topping: 1/2 tsp cinnamon',
      'topping: 1/4 cup sugar',
      'topping: 2 tsp butter',
    ],
    directions: [
      'Topping: Mix the flour, cinnamon and sugar together',
      'Topping: Add the butter and crumble with your fingers',
      'Preheat the oven to 180˚C',
      'In a large bowl, mix B, C, D, E, F and G',
      'Make a well in the middle',
      'In a medium bowl, mix H and I well. Add J and K. Mix well',
      'Add the mixture slowly to the large bowl. Mix slowly',
      'Add the blueberries and stir gently',
      'Put the batter in a muffin tray and sprinkle the topping',
      'Bake for 30-40 minutes',
      "Bon appétit"
    ],
    description: [
      "A few years ago, I went to a blueberry farm with my friends and gathered so many berries we couldn't eat them all. We found this muffin recipe online- it was easy to follow and turned out great!",
      "Don't leave out the crumble topping because that's what makes all the difference!",
    ],
    tags: [ETags.blueberry, ETags.crumble],
    photos: ['sarah-blueberries.jpg'],
    rescuer: 'Yoshiko Shimada, Japan',
  },
  {
    needHelp: true,
    name: 'brownies',
    dateAdded: '23.05.2011',
    title: 'Brownies',
    totalTime: 'PT0H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I got this recipe from a friend in high school in 1999! It quickly became my family's favourite chocolate cake and I never tire of making it!",
      'For best results, I recommend using good quality dark baking chocolate and an oven with rotating heat. Adding a table spoon of instant coffee or chopped nuts also gives it a little extra taste.',
    ],
    tags: [ETags.brownies, ETags.chocolate],
    photos: ['coming-soon.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'lemon-myrtle-cheese-cake',
    dateAdded: '27.01.2013',
    title: 'Lemon Myrtle Cheese Cake',
    totalTime: 'PT2H',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.australia],
    ingredients: ['dried lemon myrtle'],
    directions: ['we are missing the directions'],
    description: [
      "A taste of the Australian Bush on Australia Day (January 26th). I spent the Xmas holidays with my relatives in Perth and brought back a pack of dried lemon myrtle, an Australian native plant. It's stronger than regular lemon grass so I am not sure how I am going to use it all - if you know any good recipes, let me know!",
    ],
    tags: [ETags.cheese, ETags.cake, ETags.myrtle, ETags.creamCheese, ETags.simple, ETags.traditional],
    photos: ['sarah-lemon-myrtle-cheese-cake.jpg'],
    course: [ECourse.snacks, ECourse.dessert],
    rescuer: '',
    links: [
      { name: 'A taste of the Australian Bush', link: 'https://www.atasteofthebush.com.au/product-page/lemon-myrtle' },
    ],
  },
  {
    needHelp: false,
    name: 'carambar-cake',
    dateAdded: '18.06.2011',
    title: 'Carambar Cake',
    totalTime: 'PT1H',
    recipeYield: 8,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: [
      '3 eggs',
      '160g sugar',
      '150g butter',
      '20 Carambars (original - caramel)',
      '10cl milk',
      '75g flour',
      '75g maizena',
      '5-6g (half packet) baking powder',
    ],
    directions: [
      'Preheat the oven to 180˚C',
      'Place Carambars in a pot with the milk and butter, heat until Carambars are melted',
      'In a large bowl, mix sugar en egg yolks, flour, maizena, baking powder',
      'Slowly mix in the melted Carambar mixture',
      'Beat the egg whites until stiff and add them to the bowl (making sure to not mix too hard), and mix by hand until smooth',
      'Pour the mix into a previously buttered and floured cake mold',
      'Place into the oven and bake for 40-50 minutes',
      'The cake is baked when a knife comes out clean and dry',
      'Once baked, remove from the mold and place it on a cooling rack. Enjoy warm or cool',
    ],
    description: [
      "Carambar are delicious hard caramel bars and one of the most popular sweets in France. There are jokes printed inside the wrapping paper- they are so stupid, a bad joke is said to be a 'carambar joke'!",
      "You can eat them as they are or make 'Vodka Carambar' by adding dozens of bars to the bottle and let it melt for a few days. My mum's friend Anne-Marie introduced me to this cake, another great way to enjoy the unique taste of Carambar!",
    ],
    tags: [ETags.caramel, ETags.cake, ETags.egg, ETags.butter],
    photos: ['sarah-carambar-cake.jpg', 'sarah-anne-marie-recipe.jpg'],
    rescuer: 'Anne-Marie Brinquin, France',
  },
  {
    needHelp: true,
    name: 'trout-and-pesto-appetizers',
    dateAdded: '30.06.2011',
    title: 'Trout & Pesto Appetizers',
    totalTime: 'PT10M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.sarahAuffret],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I was trying to come up with something different and that needed little preparation for dinner last night, and remembered what I'd tried at Sugattiii, an Italian restaurant in Tokushima, Japan. The chef had served raw fish with pesto...and it was fantastic!",
      'So I decided to try it out with smoked trout on toast and it worked really well! Bon appétit!',
    ],
    tags: [ETags.fish, ETags.pesto, ETags.cheese],
    photos: ['sarah-trout-pesto.jpg'],
    course: [ECourse.starter],
    rescuer: '',
    links: [{ name: 'Sugattiii', link: 'https://www.sugattiii.com/' }],
  },
  {
    needHelp: false,
    name: 'leek-tart',
    dateAdded: '24.05.2011',
    title: 'Leek Tart',
    course: [ECourse.main],
    totalTime: 'PT1H',
    recipeYield: 6,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['200g butter', '200g flour','1 pinch salt','2 leeks','grated cheese','2 eggs'],
    directions: ['Prepare the tart base the night before. Mix the flour and butter with your hands until you can form a ball. Leave it in the refrigerator overnight', 'The following dy, roll the base out and pre-cook it in the oven for 20 minutes','While it pre-cooks, chop the leeks and fry them with olive oil in a pan. Whn cooked, add the eggs and mix well. Add grated cheese', 'Take the base tart out of the oven and pour the mixture in. Cook for a further 15 minutes (or until the eggs are cooked) at 180˚C', 'It goes well with salad, lentils or potatoes!', 'Bon appétit!'],
    description: [
      'My parents used to make leek tarts in the winter when I was a child. They use slightly different recipes, so I have decided to come up with my own too. Play with the ingredients, add cream, bacon, etc until you find your own favourite!',
    ],
    tags: [ETags.cheese, ETags.tart, ETags.vegetable],
    photos: ['sarah-leek-tart.jpg'],
    rescuer: 'Yoshiko Shimada, Japan',
  },
  {
    needHelp: true,
    name: 'pho-ga',
    dateAdded: '30.09.2012',
    title: 'Pho Ga',
    course: [ECourse.main],
    totalTime: 'PT10M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.asia, EOrigin.sarahAuffret],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'Autumn is slowly coming to Tokyo (finally!!!) so I am trying to find all sorts of easy & tasty soups to make.',
      "There are a lot of different noodle soups available in Japan but also in other Asian countries. I love Vietnamese food, so here's a simple and tasty dish everyone should be able to make :)!",
    ],
    tags: [ETags.soup, ETags.chicken, ETags.noodle, ETags.simple, ETags.traditional],
    photos: ['sarah-pho-ga.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'oyakodon-親子丼',
    dateAdded: '05.02.2012',
    title: 'Oyakodon 親子丼',
    course: [ECourse.main],
    totalTime: 'PT30M',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia, EOrigin.japan],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: ["Fancy eating a whole family on rice? The name of this dish is literally 'parent-child-bowl'!!!"],
    tags: [ETags.chicken, ETags.egg, ETags.rice],
    photos: ['sarah-oyakodon.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'chicken-wrapped-in-lettuce',
    dateAdded: '24.05.2011',
    title: 'Chicken Wrapped in Lettuce',
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I visited Korea for a few days in 2008 and was surprised by all the different ingredients laid out on the table. I especially enjoyed 'yakiniku' (grilled meat, a common korean japanese meal) as we used salad leaves to wrap the freshly grilled slices of beef. What a refreshing change from flour based wraps!",
      'This chicken wrap recipe only gives you some ideas of what you could put in your wrap. Feel free to add or leave out some ingredients - and share your favourite combinations with me!',
    ],
    tags: [ETags.chicken, ETags.healthy],
    photos: ['sarah-chicken-wrapped-in-lettuce.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'spicy-roasted-chickpeas',
    dateAdded: '27.06.2011',
    title: 'Spicy Roasted Chickpeas',
    totalTime: 'PT45M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'I was looking for a healthy snack to take on my 500km bus ride to the Lofoten last week and I fond a great recipe on allrecipes.com.',
      "All you need is a cup of chickpeas, olive oil and some spices and you'll have the most delicious snack! It's also a great addition to salads.",
    ],
    tags: [ETags.chickpeas, ETags.spices, ETags.garlic],
    photos: ['sarah-spicy-roasted-chickpeas.jpg'],
    course: [ECourse.snacks],
    rescuer: '',
    links: [
      { name: 'allrecipes.com', link: 'https://www.allrecipes.com/search/results/?search=Spicy+Roasted+Chickpeas' },
    ],
  },
  {
    needHelp: true,
    name: 'humus',
    dateAdded: '02.06.2011',
    title: 'Humus',
    course: [ECourse.starter],
    totalTime: 'PT5M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.sarahAuffret],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I found out today was a bank holiday quite late last night and hadn't been shopping for a few days. So I had to make do with what was in my cupboards & fridge (ie. not much!) and came up with this delicious and simple hummus.",
      "Not only is it tasty but it's also healthy as chickpeas are a great source of protein and fiber.",
    ],
    tags: [ETags.chickpeas, ETags.garlic],
    photos: ['sarah-humus.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'midnight-cookies',
    dateAdded: '14.06.2011',
    title: 'Midnight Cookies',
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.sarahAuffret],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "I was away for work for a couple of days and once again, I had forgotten the shops would be closed for two days - there's too many bank holidays here! - I had next to no food left in my cupboards.",
      'Still I wanted to go hiking and make sure my friends and I would have something to snack on so I managed to make some kind of cookies with whatever was left! And they turned out to be good... So good we ate the whole box in 30 minutes!',
    ],
    tags: [ETags.cookies, ETags.chocolate],
    photos: ['sarah-midnight-cookies.jpg'],
    course: [ECourse.snacks, ECourse.dessert],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'chewy-choco-chips-cookies',
    dateAdded: '24.05.2011',
    title: 'Chewy Choco-Chips Cookies',
    totalTime: 'PT1H30M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.americas],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'An American friend once asked me to bake him cookies but I had no idea how, so I had a look on the internet and found this recipe. Simple and tasty, I have made thousands of them for birthdays, christmas parties and sports events. So be careful, they are addictive!',
    ],
    tags: [ETags.cookies, ETags.chocolate],
    photos: ['sarah-chewy-choco-chip-cookies.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'outrageous-chocolate-cookies',
    dateAdded: '24.05.2011',
    title: 'Outrageous Chocolate Cookies',
    totalTime: 'PT2H',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.americas],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "Back in 2009, I was looking for recipe for valentine's chocolate. I had tried melting chocolate in cute heart shaped moulds but failed to take them out in one piece so I looked for other kind of chocolate loaded recipes. This is how I came across these cookies... I didn't find my Valentine, but I sure made an impression (^^)!",
    ],
    tags: [ETags.cookies, ETags.chocolate],
    photos: ['sarah-outrageous-chocolate-cookies.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'black-sticky-rice-pudding',
    dateAdded: '07.06.2011',
    title: 'Black Sticky Rice Pudding',
    totalTime: 'PT2H',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.asia],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "Two years ago, I visited northern Vietnam with my friend Maya. We were looking at what  to do in Hanoi and found out about cooking classes. We decided to attend Hoa Sua school because it's not just designed for tourists but more for disadvantaged youth throughout the country. The additional money we brought to the school felt well used!",
      'We learned how to make four different dishes: spring rolls, fried spring rolls (yes there are different!), dried beef salad and black sticky rice pudding! I was a bit skeptical about the dessert, but it turned out to be my favourite! Give it a go and let yourself surprised!',
    ],
    tags: [ETags.rice, ETags.coconut, ETags.peanuts],
    photos: ['sarah-black-rice-pudding.jpg', 'sarah-sapa.jpg'],
    rescuer: '',
    links: [{ name: 'Hoa Sua', link: 'https://www.hoasuaschool.com/web/Index.aspx?zoneid=247&lang=en-US' }],
  },
  {
    needHelp: true,
    name: 'mrs-klausen-s-cookies',
    dateAdded: '06.06.2011',
    title: "Mrs Klausen's cookies",
    totalTime: 'P1D',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "What food would you pack if you were off to the world's most extreme locations? Besides dried meat and berries, Roald Amundsen, the first man to reach the South Pole in 1911, brought hundreds of Mrs Klausen's cookies!",
      'They are rich and sweet, the perfect combination for a cold & rainy Sunday!',
    ],
    tags: [ETags.cookies, ETags.oatmeal],
    photos: ['sarah-biscuits-pole.jpg'],
    rescuer: '',
    links: [{ name: 'Roald Amindsen', link: 'https://www.south-pole.com/p0000101.htm' }],
  },
  {
    needHelp: true,
    name: 'courgette-and-potato-gratin',
    dateAdded: '21.08.2011',
    title: 'Courgette and Potato Gratin',
    course: [ECourse.main],
    totalTime: 'PT1H15M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    origin: [EOrigin.sarahAuffret],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'I am back in France for a little while so I am taking full advantage of the delicious food available - and especially fruits and vegetables!',
      "My aunt gave us loads of potatoes and courgettes (zucchini) so I decided to make a gratin. It's very easy and healthy if you leave out the cheese!",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.courgette, ETags.potato, ETags.garlic, ETags.simple],
    photos: ['sarah-gratin.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'yoghurt-and-lentils-armenian-soup',
    dateAdded: '07.01.2012',
    title: 'Yoghurt and Lentils Armenian Soup',
    course: [ECourse.main],
    totalTime: 'PT40M',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'Earlier last year I was travelling across Eastern Europe and somehow made my way into Armenia. It was rather cold and a lot of the country roads were closed due to heavy snowfall so I stayed in Yerevan only for a few days. I met a few great people at the Envoy hostel and we enjoyed walking around, trying home made apricot vodka and this amazing yoghurt soup.',
      "I haven't found the exact name or recipe - of course I didn't write it down at the time! - but it could be called Tahnabour. I'm still experimenting with different ingredients and I can't think of a substitute for the flat bread, but here's a tasty combination",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.soup, ETags.traditional, ETags.garlic, ETags.lentils, ETags.simple],
    photos: ['sarah-armenian-yogurt-soup.jpg', 'sarah-armenian-market.jpg'],
    rescuer: '',
    links: [
      { name: 'Armenia', link: 'http://www.tourismarmenia.org' },
      { name: 'Yerevan', link: 'https://www.yerevan.am/en/' },
      { name: 'Envoy Hostel', link: 'https://www.envoyhostel.com/' },
    ],
  },
  {
    needHelp: true,
    name: 'gyoza',
    dateAdded: '31.01.2011',
    title: 'Gyoza',
    course: [ECourse.snacks, ECourse.starter, ECourse.main],
    totalTime: 'PT30M',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "These delicious dumplings are originally from China but immensely popular in Japan. I have to admit I didn't like them at first as I found them too greasy. But as it's such a popular food, I had the chance to try some from different shops, and most importantly, home made ones.",
      "Some of the ingredients might be difficult to come by outside of Asia, but I am sure you'll be able to make do somehow.",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.garlic, ETags.pork],
    photos: ['sarah-gyoza.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'fresh-spring-rolls',
    dateAdded: '18.06.2011',
    title: 'Fresh Spring Rolls',
    course: [ECourse.starter],
    totalTime: 'PT30M',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.asia],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "Two years ago, I visited northern Vietnam with my friend Maya. We were looking at what to do in Hanoi and found out about cooking classes. We decided to attend Hoa Soa school because it's not just designed for tourists but more for disadvantaged youth throughout the country. The additional money we brought to the school felt well used!",
      "We learnt how to make fresh and fried spring rolls - I much prefer the fresh ones, so I have been making them regularly when I was in Japan. The different herbs are really what makes the rolls great, but don't hesitate to experiment: cream cheese and salmon is also irresistible!",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.herbs, ETags.rice],
    photos: ['sarah-spring-rolls.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'chocolate-mousse',
    dateAdded: '24.05.2011',
    title: 'Chocolate Mousse',
    totalTime: 'PT1H30M',
    recipeYield: 0,
    complexity: EComplexity.veryEasy,
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "One evening, I was craving chocolate but didn't want to eat it by itself. I looked through my cupboards and fridge and sadly realised I didn't have the ingredients to make a cake...but I found this awesome chocolate mousse recipe!",
      "It is very simple and relatively healthy because it doesn't call for cream. Bon appétit!",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.chocolate, ETags.mousse],
    photos: ['sarah-chocolate-mousse.jpg'],
    rescuer: '',
  },
  {
    needHelp: true,
    name: 'breton-cake',
    dateAdded: '23.05.2011',
    title: 'Breton cake',
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "This cake is typical for Brittany, the region where I grew up. It's called a 'far breton' in French.",
      "It has more or less the texture of hard custard or flan and taste even better with a drop of vanilla essence and soaked dried prunes. You might be surprised at first, but I am sure you'll grow to like it, as did my members of the Alliance française in Tokushima, Japan!",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.prunes, ETags.cake],
    photos: ['sarah-far-breton.jpg'],
    rescuer: '',
    links: [
      { name: 'Brittany', link: 'https://www.brittanytourism.com/' },
      {
        name: 'Alliance française in Tokushima, Japan',
        link: 'http://aftokushima.d.dooo.jp/',
      },
    ],
  },
  {
    needHelp: true,
    name: 'scones',
    dateAdded: '07.08.2011',
    title: 'Scones',
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      "My friends and I had a small party for Will&Kate's wedding last April and I was asked to make scones. I have eaten dozens of different scones but had never tried making my own so I looked for a trustworthy recipe on the BBC website.",
      "The recipe I am using here is theirs: it's easy to follow and the scones are perfect! Now I only have to attempt making clotted cream to enjoy Devon Cream Tea once again...",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.traditional],
    photos: ['sarah-scones.jpg'],
    rescuer: '',
    links: [
      { name: 'BBC recipe', link: 'https://www.bbc.co.uk/food/recipes/scones_1285' },
      { name: 'clotted cream', link: 'https://www.bbc.co.uk/food/clotted_cream' },
      { name: 'Devon cream tea', link: 'https://www.devoncreamtea.org.uk/' },
    ],
  },
  {
    needHelp: true,
    name: 'crepes',
    dateAdded: '24.05.2011',
    title: 'Crêpes',
    course: [ECourse.starter],
    totalTime: 'PT1H',
    recipeYield: 0,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: ['we are missing the ingredients'],
    directions: ['we are missing the directions'],
    description: [
      'Crepes are the traditional meal of Brittany, the region I come from. The beauty with crepes is that you can pretty much eat them at any time with whatever you want on them! While sweet crepes are better known, if you go to Brittany, you will have to try the ones filled with cheese, mushrooms, blood sausage, etc!',
      'For a more authentic Breton taste, you can also use buckwheat flour instead of regular wheat flour.',
      "PS: if you have time, let the crepe mix set for an hour, or even over night. It'll be even tastier!",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.traditional],
    photos: ['sarah-crepes.jpg'],
    rescuer: '',
  },
  {
    needHelp: false,
    name: 'Rösti',
    dateAdded: '24.05.2011',
    title: 'Rösti',
    course: [ECourse.main],
    totalTime: 'PT1H',
    recipeYield: 4,
    complexity: EComplexity.easy,
    origin: [EOrigin.europe],
    ingredients: [
      '4 big potatos',
      '3 tbs butter (or olive oil)',
      'salt',
      'optional: grated cheese, sliced onion, chopped garlic, bacon, egg, etc...',
    ],
    directions: [
      'Boil potatoes. Cool down',
      'Peel the potatoes and grate them',
      'Heat butter in pan',
      'Mix all ingredients. Season to taste',
      'Fry in pan for 10-15 minutes both sides',
      'Serve hot. It goes well with salad',
    ],
    description: [
      "It's winter and we need hot, filling food (^^)! Do you often cook with potatoes?",
      'Potatoes grow well in my hometown, so I often ate potato dishes when I was  child',
      "My favorite recipe is called Rösti and comes from Switzerland. It's like a potato pancake",
    ],
    specialTip: 'we are missing special tips',
    tags: [ETags.traditional],
    photos: ['sarah-crepes.jpg'],
    rescuer: 'Yoshiko Shimada, Japan',
  },
  // {
  //   needHelp: true,
  //   name: 'scones',
  //   dateAdded: '07.08.2011',
  //   title: 'Scones',
  //   totalTime: 'PT1H',
  //   recipeYield: 0,
  //   complexity: EComplexity.easy,
  //   origin: [EOrigin.europe],
  //   ingredients: ['we are missing the ingredients'],
  //   directions: ['we are missing the directions'],
  //   description: ['wwwwwwwwww'],
  //   specialTip: 'we are missing special tips',
  //   tags: [ETags.traditional],
  //   photos: ['sarah-scones.jpg'],
  //   rescuer: '',
  // },
]
