import { parse } from 'tinyduration'
import { recipes } from './recipes'
import { EOrigin, ETags } from './utils'

export const filterRecipesByTag = (tag: ETags) => {
  if (tag === ETags.all) {
    return recipes
  }
  return recipes.filter((x) => x.tags.some((y) => y === tag))
}

export const filterRecipesByOrigin = (origin: EOrigin) => {
  return recipes.filter((x) => x.origin && x.origin.some((y) => y === origin))
}

export const filterRecipesByCourse = (course: any) => {
  return recipes.filter((x) => x.course && x.course.some((y) => y === course))
}

export const filterRecipesByName = (name: string) => {
  return recipes.find((x) => x.name === name)
}

export const filterRecipesByNeedHelp = () => {
  return recipes.filter((x) => x.needHelp === true)
}

export const duration = (iso8601: string) => {
  const durationObj = parse(iso8601)
  let durationStr = ''
  for (const [key, value] of Object.entries(durationObj)) {
    return `${durationStr} ${value} ${key}`
  }
  return durationStr
}
