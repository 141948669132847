import React from 'react'
import FooterMenu from '../footerMenu'
import './styles.css'

const Footer: React.FC<{}> = () => {

  const date = new Date().getFullYear()
  return (
    <footer>
      <div className="top">
        <FooterMenu />
      </div>
      <div className="bottom">
        <div className="container">
          <a className="anchor" href="#page">back to top</a>
          <div>
            {`Too Many Dishes ® 2010-${date} | All Rights Reserved`}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
