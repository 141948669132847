import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'

const FooterMenu: React.FC<{}> = () => {
  return (
    <menu className="footer_menu">
      <div>
        <Link to="/legal">Legal</Link>
      </div>
      <div>
        <Link to="/contact">Contact</Link>
      </div>
      <div>
        <Link to="/need-help">Help!</Link>
      </div>
      {/* <div>
        <Link to="/about-sarah">Sarah</Link>
      </div> */}
    </menu>
  )
}

export default FooterMenu
